<template>
  <div>
    <p class="title">商品仓库</p>
    <div class="agent_search">
      <div style="padding-top:10px">
        <label class="lab" for="searchname">商品名称</label>
        <el-input clearable name="searchname" placeholder="请输入商品名称" class="goods_search" v-model="goodsname"></el-input>
        <label class="lab" for="searchnum">商品编号</label>
        <el-input clearable name="searchnum" placeholder="请输入商品编号" class="goods_search" v-model="goodsnum"></el-input>
        <Button type="primary" style="margin:0 5px 0 5px" @click="gettable(1)">搜索</Button>
        <Button type="primary" @click="reset">重置</Button>
      </div>
      <div>
        <ul class="letter_search">
          <label style="float:left;font-size:16px;margin-left:10px;">首字母搜索</label>
          <li v-for="(item, index) in letter" :key="index" @click="s_letter_search(item, index)"
            :class="{ 'active': cla == index }">
            {{ item }}
          </li>
        </ul>
      </div>

      <div style="height:50px;margin-top:40px">
        <label class="lab" for="cla">商品归类</label>
        <el-cascader placeholder="请选择充值类型" :options="class_options" :props="defaultParams" v-model="classify"
          @change="addclass()" clearable name='cla' style="width:160px;text-align: center;margin:15px 5px;"></el-cascader>

        <el-dropdown @command="handleCommand2" style="margin:14px 6px" trigger="click">
          <el-button type="primary">
            更新商品<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="2">卡盟商品更新</el-dropdown-item>
            <el-dropdown-item command="16">商盟商品更新</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button type="primary" style="margin:14px 6px" @click="change_list">更新的商品</el-button>
        <el-button type="primary" style="margin:14px 6px" @click="fail_list">更新失败商品</el-button>
        <el-button type="primary" style="margin:14px 6px" @click="addgoods">添加商品</el-button>
      </div>
      <div style="margin-top:20px">
        <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" max-height="580"
          height="560" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="id" label="商品ID" width="80">
          </el-table-column>
          <el-table-column prop="good_name" label="商品名称" width="150">
          </el-table-column>
          <el-table-column label="商品状态" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.state == 1">已上架</span>
              <span v-if="scope.row.state == 2">未上架</span>
              <!-- <span v-if="scope.row.is_release==2">未发布</span>
                     <span v-if="scope.row.is_release==1">已发布</span>
                     <span v-if="scope.row.is_release==3">维护中</span> -->
            </template>
          </el-table-column>
          <el-table-column prop="purchase_price" label="进价" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="商品销售价" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="scope.row.price" style="width:120px" @blur="pricechange(scope.row)"
                @focus="oldprice = scope.row.price"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="official_price" label="官方价" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="supplier_str" label="商品归属" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="class_str" label="商品归类" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="请先归类" style="color:#999" placement="top" v-if="!scope.row.class_str">
                <el-button type="text" title="请先归类">编辑</el-button>
              </el-tooltip>
              <el-button @click="edit(scope.row)" type="text" v-if='scope.row.class_str'>编辑</el-button>
              <el-tooltip effect="dark" content="请先归类" placement="top-start">
                <el-button type="text" style="color:#999" v-if="!scope.row.class_str">上架</el-button></el-tooltip>
              <el-button type="text" @click="grounding(scope.row)" v-if="scope.row.class_str">上架</el-button>
              <el-button type="text" @click="del(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top:5px;textAlign:center" background prev-text='上一页' next-text='下一页'
          layout="total , prev, pager, next, jumper" @current-change="current_changes" :total="totals"
          :page-size="pagesizes" :current-page.sync="currentPages">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="商品列表" :visible.sync="change_listshow" width="60%">
      <el-table ref="multipleTable" :data="failtableData" height="600px" tooltip-effect="dark" style="width: 100%"
        highlight-current-row>
        <el-table-column prop="id" label="商品ID" width="70" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="商品名称" prop="good_name" width="190">
        </el-table-column>
        <el-table-column label="商品状态" width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.is_release == 1">已发布</span>
            <span v-if="scope.row.is_release == 2">未发布</span>
            <span v-if="scope.row.is_release == 3">维护中</span>
          </template>
        </el-table-column>
        <el-table-column prop="purchase_price" label="进价" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="price" label="商品销售价" width="100" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column
      prop="price_cap"
      label="价格上限"
      width="100"
      show-overflow-tooltip>
    </el-table-column> -->
        <el-table-column label="更新类型" width="130">
          <template slot-scope="scope">
            <span v-if="scope.row.third_party_updates == 2">名称更新</span>
            <span v-else-if="scope.row.third_party_updates == 1">价格更新</span>
            <span v-else-if="scope.row.third_party_updates == 3">名称价格更新</span>
          </template>
        </el-table-column>
        <el-table-column prop="s_name" label="商品归属" width="120">
        </el-table-column>
        <el-table-column width="130" align="left">
          <template slot-scope="scope">
            <el-dropdown trigger="click" split-button type="primary">
              操作
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="scope.row.is_release == 2"
                  @click.native="torelease(scope.row)">发布</el-dropdown-item>
                <el-dropdown-item v-if="scope.row.is_release == 1"
                  @click.native="Norelease(scope.row)">取消发布</el-dropdown-item>
                <el-dropdown-item @click.native="goods_edit(scope.row)">编辑</el-dropdown-item>
                <el-dropdown-item v-if="scope.row.state == 2" @click.native="issue(scope.row)">上架</el-dropdown-item>
                <el-dropdown-item v-if="scope.row.state == 1" @click.native="todrop_up(scope.row)">下架</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="margin-top:5px;textAlign:center" background prev-text='上一页' next-text='下一页'
        layout="total , prev, pager, next, jumper" @current-change="current_change" :total="total" :page-size="pagesize"
        :current-page.sync="currentPage">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="change_listshow = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="取消发布商品" :visible.sync="releasedialog" width="500px">
      <p style="text-align:center">
        <label>验证码：</label><el-input placeholder="请输入验证码" v-model="tradcode" maxlength="8"
          style="width: 190px;"></el-input>
        <span class="tips">（若要下架商品请输入验证码&nbsp;{{ randomnum }}）</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="releasedialog = false">取 消</el-button>
        <el-button type="primary" @click="Norelease()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="商品列表" :visible.sync="failgood" center width="70%">
      <el-table :data="failtableData" border>
        <el-table-column prop='id' label='商品ID'>
        </el-table-column>
        <el-table-column prop='coding' label='商品编码'>
        </el-table-column>
        <el-table-column prop='s_name' label='商品归属'>
        </el-table-column>
        <el-table-column prop='good_name' label='商品名称'>
        </el-table-column>
        <el-table-column label='操作'>
          <template slot-scope="scope">
            <el-button type="text" @click="faildel(scope.row)" style="color:red">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="failgood = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default ({
  inject: ['chanlist'],
  data() {
    return {
      oldprice: "",
      currentPages: 1,
      pagesizes: 10,
      totals: 0,
      failgood: false,
      currentPage: 1,
      pagesize: 10,
      total: 0,
      row_value: "",
      releasedialog: false,
      tradcode: '',//交易密码
      randomnum: '',//随机密码
      failtableData: [],
      change_listshow: false,
      org_price: "",//进价
      form: {},
      dialogVisible: false,
      english: "",
      tableData: [],
      multipleSelection: [],
      s_status: "",
      classify: "",
      cla: -1,
      goodsname: "",
      goodsnum: "",
      letter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],//26字母
      class_options: [],
      defaultParams: {
        label: 'class_name',
        value: 'id',
        children: 'children',
        emitPath: false,
      },
    }
  },
  mounted() {
    this.chanlist('1-1', '1')
    this.getclass()
    this.gettable()
  },
  methods: {

    pricechange(row) {
      let reg = /^([0-9][0-9]*)+(\.[0-9]{1,4})?$/
      if (!reg.test(row.price)) {
        this.$message({
          type: "warning",
          message: "请输入正确商品的销售价"
        })
        this.gettable()
      } else {
        if (parseFloat(row.price) == parseFloat(this.oldprice)) {
          this.gettable()
          return
        }
        this.$axios.post('/supplier/good/change.price', { gid: row.id, price: row.price }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              type: "success",
              message: res.data.message
            })
            this.gettable()
          } else {
            this.$message({
              type: "warning",
              message: res.data.message
            })
            this.gettable()
          }
        })
      }
    },
    faildel(row) {
      this.$confirm('您是否要删除此商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/supplier/good/delete", { gid: row.id }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: res.data.message
            });
            this.fail_goodslist()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            });
          }
        })
      })
    },
    current_change(page) {
      this.currentPage = page
      this.change_goodslist()
    },
    current_changes(page) {
      this.currentPages = page
      this.gettable()
    },
    goods_edit(row) {
      this.$router.push({ name: "Edit", params: { row: row, list: 1 } })
    },
    todrop_up(row) {
      this.$axios.post("/supplier/good/rack.up.and.down", { gid: row.id, rack_up_and_down: 2 }).then(res => {
        if (res.data.code == 20000) {
          this.$message({
            type: 'success',
            message: res.data.message
          });
          this.change_goodslist()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          });
        }

      })
    },
    issue(row) {
      this.$confirm('您确定要上架该商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
      }).then(() => {
        this.$axios.post("/supplier/good/rack.up.and.down", { gid: row.id, rack_up_and_down: 1 }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: res.data.message
            });
            this.change_goodslist()
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            });
          }

        })

      })
    },
    create_tradnum() {
      var b = ''
      for (let i = 0; i < 8; i++) {
        let a = parseInt(Math.floor((Math.random() * 10)));
        b = b + a.toString()
      }
      this.randomnum = b
    },
    Norelease(row) {
      if (this.releasedialog == true) {
        if (this.tradcode == '') {
          this.$message({
            message: "请输入验证码！",
            type: 'error',
            center: 'true',
          });
          return;
        } else if (this.tradcode != this.randomnum) {
          this.$message({
            message: "验证码错误请重新输入！",
            type: 'error',
            center: 'true',
          });
          return;
        }
      }
      this.releasedialog = !this.releasedialog;
      if (row != undefined) {
        this.row_value = row;
        this.create_tradnum()
      }
      if (this.releasedialog == false) {
        this.$axios.post("/supplier/good/release", { type: 2, gids: this.row_value.id }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              type: "success",
              message: res.data.message
            })
            this.change_goodslist()
          } else {
            this.$message({
              type: "warning",
              message: res.data.message
            })
          }
        })
      }
    },
    torelease(row) {
      this.$axios.post("/supplier/good/release", { type: 1, gids: row.id }).then(res => {
        if (res.data.code == 20000) {
          this.$message({
            type: "success",
            message: res.data.message
          })
          this.change_goodslist()
        } else {
          this.$message({
            type: "warning",
            message: res.data.message
          })
        }
      })
    },
    //删除
    del(row) {
      this.$confirm('您是否要删除此商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/supplier/good/delete", { gid: row.id }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: res.data.message
            });
            this.gettable(1)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            });
          }
        })
      })
    },
    //上架
    grounding(row) {
      this.$confirm('您确定要上架该商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/supplier/good/rack.up.and.down", { gid: row.id, rack_up_and_down: 1 }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: '上架成功'
            });
            this.gettable(1)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            });
          }
        })
      })
    },
    //编辑
    edit(row) {
      this.$router.push({ name: "Edit", params: { row: row, list: 1 } })
    },
    //table选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //添加商品
    addgoods() {
      this.$router.push({ path: "/homes/addgoods" })
    },
    //更新失败的商品
    fail_list() {
      this.fail_goodslist()
      this.failgood = true
    },
    fail_goodslist() {
      this.$axios.post("/supplier/good/good.list.update.error").then(res => {
        if (res.data.code == 20000) {
          this.failtableData = res.data.data.rows
        } else {
          this.$message({
            type: "warning",
            message: res.data.message
          })
        }
      })
    },
    //更新的商品
    change_list() {
      this.change_goodslist()
      this.change_listshow = true
    },
    change_goodslist() {
      this.$axios.post("/supplier/good/good.list.update", { page: this.currentPage, limit: this.pagesize }).then(res => {
        if (res.data.code == 20000) {
          this.failtableData = res.data.data.rows
          this.total = res.data.data.total
        }
      })
    },
    //商品归类
    addclass() {
      var checked = this.multipleSelection;
      if (checked == '') {
        this.$message({
          message: "请先勾选商品",
          type: 'error',
          center: 'true',
          duration: '2000'
        });
        this.classify = []
        return false
      }
      var gids = new Array()
      for (let i = 0; i < checked.length; i++) {
        gids.push(checked[i].id)
      }
      this.$axios.post("/supplier/good/categorize", { gids: String(gids), class_id: this.classify })
        .then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              message: res.data.message,
              type: 'success',
            });
            this.gettable(1)
            this.classify = []
            this.multipleSelection = []
          } else {
            this.$message({
              message: res.data.message,
              type: 'warning',
            });
            this.classify = []
            this.multipleSelection = []
          }
        })
    },
    getclass() {
      this.$axios.post('/supplier/good/class').then((res) => {
        this.class_options = res.data.data
      })
    },
    s_letter_search(item, index) {
      this.cla = index;
      this.english = item
      this.gettable(1)
    },
    reset() {
      this.goodsname = ''
      this.goodsnum = ''
      this.cla = -1
      this.english = ''
      this.gettable(1)
    },
    handleCommand2(command) {
      if (command == 10) {
        this.lanse_add()
      } else if (command == 16) {
        this.shangmeng_updata()
      } else {
        this.s_status = command;
        this.updata_goods()
      }
    },
    lanse_add() { },
    shangmeng_updata() {
      this.$axios.post("/supplier/good/three.party.commodity.inspection", { type: 16 }).then(res => {
        if (res.data.code == 20000) {
          this.$message({
            message: '更新成功',
            type: 'success',
            center: 'true',
          });
          this.gettable(1)
        } else {
          this.$message({
            message: res.data.message,
            type: 'error',
            center: 'true',
          });
        }
      })
    },
    updata_goods() {
      // const loading = this.$loading({
      //   lock: true,
      //   text: '更新中',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      this.$axios.post("/supplier/good/three.party.commodity.inspection", { type: 2 }).then(res => {
        if (res.data.code == 20000) {
          this.$message({
            message: '更新成功',
            type: 'success',
            center: 'true',
          });
          this.gettable(1)
          // loading.close()
        } else {
          // loading.close();
          this.$message({
            message: res.data.message,
            type: 'error',
            center: 'true',
          });
        }
      })
    },
    gettable(page) {
      if (page == 1) {
        this.currentPages = 1
      }
      this.$axios.post("/supplier/good/list/page", { type: 2, page: this.currentPages, limit: this.pagesizes, good_name: this.goodsname, coding: this.goodsnum, initials: this.english })
        .then((res) => {
          if (res.data.code == 20000) {
            this.tableData = res.data.data.rows
            this.totals = res.data.data.total
          }
        })
    }
  },

})
</script>

<style scoped>
.agent_search {
  width: 99%;
  height: 100px;
  border: 1px solid #ccc;
  margin-top: 10px;
  float: left;
  text-align: left;
  margin-left: 10px;
}

.goods_search {
  width: 220px;
  height: 25px;
  border-color: initial;
  margin-left: 3px
}

.title {
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  color: #000;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

.letter_search {
  height: 20px;
  list-style: none;
  text-align: center;
  display: block;
  width: 900px;
  margin: 10px auto;
  line-height: 20px;
  float: left;
}

.letter_search li {
  float: left;
  height: 18px;
  display: block;
  width: 30px;
  cursor: pointer;
}

.lab {
  font-size: 16px;
  margin: 10px 10px 10px 20px;
  display: inline-block
}

.active {
  background: #3743D6;
  color: #fff;
}

/* tr{height:20px} */
.chan {
  color: blue;
  cursor: pointer;
  padding-left: 10px;
}

.td1 {
  width: 150px;
  text-align: right;
}

.tips {
  display: inline-block;
  color: red;
  margin-left: 16px;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
}
</style>
